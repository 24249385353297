import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import Icon from "modules/DesignSystem/components/Icon";

import { ActionBtn, Actions, CloseBtn, Message, Root, Title } from "./styled";
import { toasterConfig } from "../../config";

import InfoIcon from "./icon/info.svg";
import SuccessIcon from "./icon/success.svg";
import WarningIcon from "./icon/warning.svg";
import ErrorIcon from "./icon/error.svg";

declare global {
  type ToastType = "info" | "success" | "warning" | "error";
}

interface Props extends IToast {
  onClose: (id: string) => void;
}

const Toast: FC<Props> = ({
  actions,
  actionsInline = false,
  autoClose = 5000,
  canClose = true,
  id,
  message,
  onClose,
  title,
  type,
}) => {
  /*
   * Refs
   */
  const rootRef = useRef<HTMLDivElement>();

  /*
   * States
   */
  // Toast height for closing animation. Is set only when closing
  const [rootHeight, setRootHeight] = useState<number>(0);
  // Timeout ID for autoClose
  const [timeoutId, setTimeoutId] = useState<number | NodeJS.Timeout>(null);

  /*
   * Callbacks
   */
  const handleClose = useCallback(() => {
    if (rootRef.current) {
      setRootHeight(rootRef.current.offsetHeight);
    }

    setTimeout(() => onClose(id), toasterConfig.closingDuration);
  }, [id, onClose]);

  const handleAction = useCallback(
    (action: IToastAction) => {
      if (action.onClick) {
        action.onClick();
      }

      if (action.closeOnClick) {
        handleClose();
      }
    },
    [handleClose]
  );

  /*
   * Memos
   */
  const TypeIcon = useMemo(() => {
    switch (type) {
      case "info":
        return InfoIcon;
      case "success":
        return SuccessIcon;
      case "warning":
        return WarningIcon;
      case "error":
        return ErrorIcon;
      default:
        return () => null;
    }
  }, [type]);

  const isClosing = rootHeight > 0;

  /*
   * Effects
   */
  useEffect(() => {
    if (autoClose) {
      setTimeoutId(setTimeout(() => handleClose(), autoClose));
    }
  }, [autoClose, handleClose]);

  useEffect(() => {
    return () => timeoutId && clearTimeout(timeoutId);
  }, [timeoutId]);

  /*
   * Render
   */
  return (
    <Root autoClose={timeoutId && autoClose} closed={isClosing} height={rootHeight} ref={rootRef} type={type}>
      {canClose !== false && (
        <CloseBtn onClick={handleClose}>
          <Icon name="close" size={18} />
        </CloseBtn>
      )}
      <Title>
        <TypeIcon />
        {title}
      </Title>
      {message && <Message>{message}</Message>}
      {actions && (
        <Actions inline={actionsInline}>
          {actions.map((btn) => (
            <ActionBtn alt={btn.alt} key={btn.text} onClick={() => handleAction(btn)}>
              {btn.text}
            </ActionBtn>
          ))}
        </Actions>
      )}
    </Root>
  );
};

export default Toast;

import React, { useState } from "react";
import { useTranslations } from "next-intl";

import { useUser } from "modules/user";
import { useSignInRedirectParams } from "modules/App/useSignInRedirectParams";
import IconList from "modules/DesignSystem/components/IconList";
import ButtonPrimitive from "modules/DesignSystem/components/ButtonPrimitive";

import { CartButton } from "./CartButton";
import { FavouriteButton } from "./FavouriteButton";
import { IconsStyled, LoginBtn, MenuLink, NewIcon } from "./IconButtons.styled";
import Dropdown from "./Dropdown/Dropdown";
import { trackUpperRightMenuClickEvent } from "./trackUpperRightMenuClickEvent";
import { UserMenu } from "./UserMenu/UserMenu";

export const IconButtons = () => {
  const { isLoggedIn } = useUser();
  const t = useTranslations();
  const { signInRedirectParams } = useSignInRedirectParams();

  const [userMenuVisible, setUserMenuVisible] = useState(false);

  const toggleUserMenu = () => {
    setUserMenuVisible((visible) => !visible);
  };

  return (
    <>
      <IconsStyled>
        <IconList>
          <div className="show-on-mobile">
            <CartButton />
          </div>
          <FavouriteButton />
          <MenuLink
            route="shelf"
            title={t("navigation.my_shelf")}
            onClick={() => trackUpperRightMenuClickEvent("collection")}
          >
            <NewIcon icon="bookshelf" width="24" height="24" />
          </MenuLink>
          <MenuLink
            route="recentlyPlayed"
            title={t("navigation.recently_played")}
            onClick={() => trackUpperRightMenuClickEvent("last_listen")}
          >
            <NewIcon icon="history" width="24" height="24" />
          </MenuLink>
          {isLoggedIn ? (
            <ButtonPrimitive className="show-on-mobile-only" onClick={toggleUserMenu}>
              <NewIcon icon="user" width="24" height="24" />
            </ButtonPrimitive>
          ) : (
            <LoginBtn
              route="signIn"
              params={signInRedirectParams}
              className="show-on-mobile-only"
              title={t("navigation.sign_in")}
              onClick={() => trackUpperRightMenuClickEvent("login")}
            >
              <NewIcon icon="user" width="24" height="24" />
            </LoginBtn>
          )}
          {isLoggedIn ? (
            <Dropdown />
          ) : (
            <LoginBtn
              route="signIn"
              params={signInRedirectParams}
              title={t("navigation.sign_in")}
              onClick={() => trackUpperRightMenuClickEvent("login")}
            >
              <NewIcon icon="user-alt" width="18" height="18" />
              {t("navigation.sign_in")}
            </LoginBtn>
          )}
        </IconList>
      </IconsStyled>

      <UserMenu open={userMenuVisible} onClose={() => setUserMenuVisible(false)} />
    </>
  );
};

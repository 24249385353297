import React, { ComponentProps } from "react";
import api from "api-web-client";
import App, { AppContext } from "next/app";

import { setUser } from "modules/user";
import { AppContainer, getDeviceID } from "modules/App";
import { AppProviders } from "modules/App/AppProviders";
import { getScreenCatalog } from "resources/AudiotekaApi";
import { getCatalogItems } from "utils/getCatalogItems";
import { isServer } from "utils/runtime";
import { getLocationInfo } from "utils/getLocationInfo";

// Global styles needs to be imported in _app.tsx (NextJS)
import "shaka-player/dist/controls.css";
import "./styles/_normalize.css";
import "./styles/_default.css";
import "./styles/_scrollbar.css";

api.onMe = setUser;

api.initialize(process.env.API_URL, {
  command: "RefreshTokenForWeb",
  payload: {
    device_id: getDeviceID(),
  },
});

if (!isServer) {
  api.subscribe(({ commandName, response }) => {
    if (commandName === "RefreshTokenForWeb" && response.status === 422) {
      // Failed to refresh token - logout user.
      setUser(null);
    }
  });
}

function AudiotekaApp({ messages, ...props }: ComponentProps<typeof AppContainer> & { messages: any }) {
  return (
    <AppProviders messages={messages}>
      <AppContainer {...props} />
    </AppProviders>
  );
}

AudiotekaApp.getInitialProps = async (appContext: AppContext) => {
  const {
    req,
    query: { locale },
  } = appContext.ctx;

  const { catalogId } = getLocationInfo(locale as string);

  const appProps = await App.getInitialProps(appContext);
  const catalog = await getScreenCatalog(catalogId);

  if (req) {
    return {
      ...appProps,
      catalogItems: getCatalogItems(catalog),
      messages: (await import(`../../public/static/locales/${locale === "cz" ? "cs" : locale}/common.json`)).default,
    };
  }

  return appProps;
};

export default AudiotekaApp;

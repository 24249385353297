import React, { useEffect } from "react";

import { deeplinkTranslator } from "modules/App/deeplinkTranslator";
import { useTeaserTracking } from "modules/teaser-tracking";
import { NewIcon } from "components/NewIcon";

import {
  Author,
  Cover,
  CoverContainer,
  Footer,
  FooterSpacer,
  FooterTop,
  MainLink,
  Progress,
  Rating,
  Root,
  Title,
} from "./styled";
import { ActionButton } from "./ActionButton/ActionButton";
import { FooterBottom, FooterRight } from "./Footer";

interface Props {
  action?: React.ComponentProps<typeof ActionButton>["action"];
  colorless?: boolean;
  dark?: boolean;
  lazyLoading?: boolean;
  noPrice?: boolean;
  onClick?: (product: HALProduct) => void;
  product: HALProduct;
  progress?: number;
}

const Teaser = ({ action = "play", colorless, dark, lazyLoading, noPrice, onClick, product, progress }: Props) => {
  const teaserTracking = useTeaserTracking();

  const { deeplink: link, description: author, image_url: cover, name: title, slug, rating } = product.data;
  const linkProps = deeplinkTranslator(link, slug);
  const isPlayable = linkProps.route === "audiobook";

  useEffect(() => {
    teaserTracking.addTeaser(product.data.id, product);

    return () => {
      teaserTracking.removeTeaser(product.data.id);
    };
  }, [product.data.id, teaserTracking]);

  return (
    <Root className="adtk-item" colorless={colorless} data-item-id={product.data.id} dark={dark}>
      <CoverContainer>
        <Cover loading={lazyLoading ? "lazy" : null} src={`${cover}?w=360&auto=format`} alt={title} />
        <ActionButton action={action} isPlayable={isPlayable} slug={slug} />
        {!!progress && progress > 0 && <Progress progress={progress} />}
      </CoverContainer>
      <Title>{title}</Title>
      {author && <Author colorless={colorless}>{author}</Author>}
      <Footer>
        <FooterTop>
          {rating > 0 && (
            <Rating colorless={colorless}>
              <NewIcon icon="star" width="13" height="13" />
              {Math.floor(rating * 10) / 10}
            </Rating>
          )}
          <FooterSpacer />
          <FooterRight colorless={colorless} productData={product.data} noPrice={noPrice} />
        </FooterTop>
        <FooterBottom productData={product.data} noPrice={noPrice} />
      </Footer>
      <MainLink onClick={() => onClick?.(product)} title={product.data.name} {...linkProps} />
    </Root>
  );
};

export default Teaser;

import React from "react";
import styled from "styled-components";
import { useTranslations } from "next-intl";

import Container from "modules/DesignSystem/components/Container";
import { MEDIA_QUERY } from "modules/DesignSystem/breakpoint";

import { MenuMobileItem } from "../MenuMobile";
import { Link, Column, LinkList, Title } from "./Footer.styled";

const CatalogItem = styled(Link)<{ accent?: boolean }>`
  color: ${({ accent }) => (accent ? "#33adff" : "#fff")};

  &:hover {
    color: #9dd7ff;
  }
`;

const Wrapper = styled.div`
  margin-bottom: 20px;
  background-color: #160829;
  width: 100%;
  align-self: center;
  align-content: start;
  display: grid;
  grid-template-columns: 1fr;
  padding-top: 24px;

  @media ${MEDIA_QUERY.md} {
    margin-bottom: 0px;
    background: #22173d;
  }
`;

const List = styled(LinkList)`
  grid-auto-flow: row;
  grid-template-columns: 1fr;

  @media ${MEDIA_QUERY.md} {
    grid-auto-flow: column;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(13, auto);
    padding-bottom: 24px;
  }

  @media ${MEDIA_QUERY.xl} {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(7, auto);
  }
`;

export const Catalog = ({ catalogItems }: { catalogItems: MenuMobileItem[] }) => {
  const t = useTranslations();

  const [isVisible, setIsVisible] = React.useState(false);

  return (
    <Wrapper>
      <Container extended>
        <Column>
          <Title expanded={isVisible} onClick={() => setIsVisible((_isVisible) => !_isVisible)}>
            {t("footer.catalog")}
          </Title>
          <List expanded={isVisible}>
            {catalogItems.map(({ accent, name, route, params, href }) => (
              <CatalogItem accent={accent} route={route} params={params} href={href} key={`catalog-item-${name}`}>
                {name}
              </CatalogItem>
            ))}
          </List>
        </Column>
      </Container>
    </Wrapper>
  );
};

import React, { MouseEventHandler } from "react";
import { useTranslations } from "next-intl";

import { useCart } from "../cart.hooks";
import { Root, Title } from "./mini-cart.styled";
import { Empty } from "./empty";
import { NonEmpty } from "./non-empty";

interface Props {
  onMouseEnter: MouseEventHandler;
  onMouseLeave: MouseEventHandler;
  open: boolean;
}

export function MiniCart({ onMouseEnter, onMouseLeave, open }: Props) {
  const cart = useCart();
  const t = useTranslations();

  return (
    <Root open={open} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Title>{t("cart.title", { count: cart.items.length })}</Title>
      {cart.items.length > 0 ? <NonEmpty /> : <Empty />}
    </Root>
  );
}

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";

import { Breakpoint } from "modules/DesignSystem/breakpoint";
import { NewIcon } from "components/NewIcon";
import { useUser } from "modules/user";
import { getUserCatalog } from "resources/AudiotekaApi";
import { getCatalogItems } from "utils/getCatalogItems";

import { MenuMobileItem } from "../MenuMobile";
import TopPromoBar from "./TopPromoBar";
import { CatalogDropdown } from "./CatalogDropdown/CatalogDropdown";
import {
  CatalogBtn,
  CatalogBtnMobile,
  CloseSearchButton,
  Column,
  ColumnWithIcons,
  Container,
  FlexWrapper,
  LogoLink,
  LogoStyled,
  TopBarStyled,
  TopBarBg,
} from "./TopBar.styled";
import { CatalogMenu } from "./CatalogMenu";
import { SearchInputDesktop, SearchInputMobile, useSearchTerm } from "./SearchInput";
import { SearchAndRecommendationResults } from "./SearchAndRecommendationResults";
import { IconButtons } from "./IconButtons";

interface Props {
  catalogItems: MenuMobileItem[];
}

const TopBar = ({ catalogItems }: Props) => {
  const catalogBtnRef = useRef<HTMLButtonElement>(null);
  const catalogContainerRef = useRef<HTMLDivElement>(null);
  const menuMobileRef = useRef<HTMLDivElement>(null);
  const router = useRouter();

  const { isLoggedIn, isLoading } = useUser();
  const t = useTranslations();

  const [isCatalogVisible, setCatalogVisible] = useState(false);
  const [isMobileInputHidden, setIsMobileInputHidden] = useState(false);
  const [catalog, setCatalog] = useState<MenuMobileItem[]>(catalogItems);

  const [searchVisible, setSearchVisible] = useState(false);
  const { searchTerm, onSearchTermChange, results, clearResults } = useSearchTerm();

  const closeResults = React.useCallback(() => {
    setSearchVisible(false);
    clearResults();
  }, []);

  useEffect(() => {
    closeResults();
  }, [router.asPath, closeResults]);

  const toggleCatalog = useCallback(() => {
    setCatalogVisible((visible) => !visible);
  }, []);

  useEffect(() => {
    async function loadUserCatalog() {
      if (!isLoading && isLoggedIn) {
        try {
          const userCatalog = await getUserCatalog();
          const userCatalogItems = getCatalogItems(userCatalog);
          setCatalog(userCatalogItems);
          return;
        } catch {
          setCatalog(catalogItems);
        }
      }
      setCatalog(catalogItems);
    }

    loadUserCatalog();
  }, [isLoading, isLoggedIn]);

  // Handle outside catalog click when visible
  useEffect(() => {
    if (isCatalogVisible) {
      const hOutsideClick = (event: PointerEvent) => {
        const path = event.composedPath();

        if (event.target instanceof HTMLElement) {
          if (
            path.includes(catalogContainerRef.current) ||
            path.includes(catalogBtnRef.current) ||
            path.includes(menuMobileRef.current)
          ) {
            if (event.target.tagName === "A") {
              setCatalogVisible(false);
            }
          } else {
            setCatalogVisible(false);
          }
        }
      };

      document.addEventListener("click", hOutsideClick);

      return () => {
        document.removeEventListener("click", hOutsideClick);
      };
    }

    return () => undefined;
  }, [isCatalogVisible]);

  // Recalculate offset left on catalog open && window resize
  useEffect(() => {
    if (isCatalogVisible) {
      const checkOffsetLeft = () => {
        const catalogOffsetLeft = parseFloat(catalogContainerRef.current.style.left || "0");

        if (window.innerWidth < Breakpoint.md) {
          if (catalogOffsetLeft !== 0) {
            catalogContainerRef.current.style.left = "0";
          }
        } else if (catalogOffsetLeft !== catalogBtnRef.current.offsetLeft) {
          catalogContainerRef.current.style.left = `${catalogBtnRef.current.offsetLeft}px`;
        }
      };

      checkOffsetLeft();

      window.addEventListener("resize", checkOffsetLeft);

      return () => {
        window.removeEventListener("resize", checkOffsetLeft);
      };
    }

    return () => undefined;
  }, [isCatalogVisible]);

  return (
    <TopBarStyled id="layout-top-bar" searchVisible={searchVisible} isMobileInputHidden={isMobileInputHidden}>
      <TopPromoBar />
      <TopBarBg>
        <Container>
          <FlexWrapper>
            <Column>
              <CatalogBtnMobile onClick={toggleCatalog}>
                <NewIcon icon="hamburger" width="24" height="24" />
              </CatalogBtnMobile>
              <LogoLink route="home" aria-label="Audioteka">
                <LogoStyled />
              </LogoLink>
              <CatalogBtn onClick={toggleCatalog} open={isCatalogVisible} ref={catalogBtnRef}>
                {t("navigation.catalog")}
                <NewIcon icon="chevron-down" width="16" height="16" />
              </CatalogBtn>
            </Column>
            <SearchInputDesktop
              value={searchTerm}
              onChange={onSearchTermChange}
              clearResults={closeResults}
              onFocus={() => setSearchVisible(true)}
            />
            <ColumnWithIcons>
              <IconButtons />
              <CloseSearchButton searchVisible={searchVisible} onClick={closeResults}>
                {t("navigation.close_search")}
              </CloseSearchButton>
            </ColumnWithIcons>
          </FlexWrapper>
          <CatalogDropdown
            items={catalog}
            isVisible={isCatalogVisible}
            containerRef={catalogContainerRef}
            onToggle={toggleCatalog}
          />
        </Container>
        <SearchInputMobile
          value={searchTerm}
          onChange={onSearchTermChange}
          clearResults={closeResults}
          onFocus={() => setSearchVisible(true)}
          onVisibilityChange={setIsMobileInputHidden}
          isHidden={isMobileInputHidden}
        />
        <CatalogMenu open={isCatalogVisible} items={catalog} onClose={toggleCatalog} />
        <SearchAndRecommendationResults
          searchResults={results}
          searchTerm={searchTerm}
          visible={searchVisible}
          onClose={closeResults}
        />
      </TopBarBg>
    </TopBarStyled>
  );
};

export default TopBar;

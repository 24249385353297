export const config = {
  columns: [
    {
      title: "Zákaznická podpora",
      expandable: true,
      links: [
        { href: "https://audioteka.com/cz/terms-of-use", text: "Obchodní podmínky" },
        { route: "privacyPolicy", text: "Zásady ochrany osobních údajů" },
        {
          href: "https://web.audioteka.com/legal/cz/Audioteka-Klub-Obchodni-podminky.pdf",
          text: "Audioteka Klub - Obchodní podmínky",
        },
        {
          href: "https://web.audioteka.com/legal/cz/tos-club.pdf",
          text: "Audioteka Klub - Obchodní podmínky členství na dobu určitou",
        },
        { route: "helpCenter", text: "Kontakt a nápověda" },
      ],
    },
    {
      title: "Informace",
      expandable: true,
      links: [
        { href: "https://pages.audioteka.com/cz/audioteka-klub", text: "Předplatné Audioteka Klub" },
        { href: "https://pages.audioteka.com/cz/darkove-poukazy/", text: "Dárkové poukazy" },
        { href: "https://pages.audioteka.com/cz/budteslyset/", text: "Buďte slyšet - Program pro autory a scenáristy" },
        { href: "https://audioteka.com/cz/static/ke-stazeni", text: "Pro média" },
      ],
    },
    {
      title: "Aplikace",
      apps: {
        android: "https://web.audioteka.com/cz/l/android",
        ios: "https://web.audioteka.com/cz/l/ios",
      },
      links: [{ href: "https://audioteka.com/cz/applications", text: "Vše" }],
    },
  ],
  social: [
    {
      href: "https://www.facebook.com/audioteka.cz",
      icon: "facebook",
    },
    {
      href: "https://www.youtube.com/audiotekacz",
      icon: "youtube",
    },
    {
      href: "https://www.instagram.com/audioteka_czsk/",
      icon: "instagram",
    },
    {
      href: "https://x.com/audioteka_cz",
      icon: "twitter",
    },
  ],
  copy: `Copyright © 2011-${new Date().getFullYear()} Audiotéka.cz s.r.o.`,
};

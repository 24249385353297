import { config as pl } from "./pl";
import { config as cz } from "./cz";
import { config as sk } from "./sk";
import { config as de } from "./de";
import { config as lt } from "./lt";

export const config = {
  pl,
  cz,
  sk,
  de,
  lt,
};

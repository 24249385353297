import React, { type SVGProps } from "react";

type IconName =
  | "info"
  | "home"
  | "download"
  | "download-alt"
  | "cycle"
  | "badge"
  | "rate"
  | "apple"
  | "window"
  | "cover"
  | "list"
  | "person"
  | "people"
  | "devices"
  | "document"
  | "facebook"
  | "youtube"
  | "instagram"
  | "twitter"
  | "add-to-cart"
  | "check"
  | "mobile-phone"
  | "hand-with-hearts"
  | "fire"
  | "touchscreen"
  | "credit-card"
  | "headphones"
  | "headphones-alt"
  | "promo"
  | "share"
  | "kid"
  | "envelope"
  | "lock"
  | "eye"
  | "eye-crossed"
  | "star"
  | "star-half"
  | "star-empty"
  | "list-border"
  | "unlock"
  | "play"
  | "pause"
  | "seek"
  | "volume"
  | "volume-muted"
  | "speed"
  | "heart"
  | "heart-border"
  | "shelf"
  | "bookshelf"
  | "history"
  | "gear"
  | "logout"
  | "user"
  | "user-alt"
  | "close"
  | "shopping-cart"
  | "menu"
  | "search"
  | "trash"
  | "hamburger"
  | "club-logo"
  | "chevron-up"
  | "chevron-down"
  | "chevron-left"
  | "chevron-right"
  | "popular"
  | "recommended"
  | "unChecked"
  | "checked";

export const NewIcon = ({
  icon,
  iconRef,
  ...props
}: SVGProps<SVGSVGElement> & { icon: IconName; iconRef?: React.MutableRefObject<SVGSVGElement> }) => {
  return (
    <svg {...props} ref={iconRef}>
      <use href={`/sprite-03092024.svg#${icon}`} />
    </svg>
  );
};

import styled from "styled-components";
import { MEDIA_QUERY } from "modules/DesignSystem/breakpoint";

interface TitleProps {
  center?: boolean;
  first?: boolean;
}

const Title = styled.h2.attrs<TitleProps>((props) => ({
  center: props.center || false,
  first: props.first || false,
}))<TitleProps>`
  font-weight: bold;
  font-size: 18px;
  margin: ${({ first }) => (first ? "0 0 16px 0" : "16px 0")};

  @media ${MEDIA_QUERY.md} {
    font-size: 26px;
  }

  @media ${MEDIA_QUERY.lg} {
    text-align: ${({ center }) => (center ? "center" : "left")};
    ${({ first }) => (!first ? "margin-top: 24px;" : "")}
    margin-bottom: 24px;
  }
`;

export default Title;

"use client";

import styled, { css } from "styled-components";
import { GUTTER, MEDIA_QUERY } from "modules/DesignSystem/breakpoint";

const Container = styled.div<{ extended?: boolean; full?: boolean }>`
  width: 100%;
  margin: 0 auto;
  padding: 0 ${GUTTER / 2}px;

  ${({ extended, full }) =>
    !full
      ? css`
          @media ${MEDIA_QUERY.sm} {
            max-width: 540px;
          }

          @media ${MEDIA_QUERY.md} {
            max-width: 720px;
          }

          @media ${MEDIA_QUERY.lg} {
            max-width: 960px;
          }

          @media ${MEDIA_QUERY.xl} {
            max-width: 1140px;
          }

          @media ${MEDIA_QUERY.xxl} {
            max-width: ${extended ? "1832" : "1500"}px;
          }
        `
      : ""}
`;

export default Container;

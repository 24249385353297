import React from "react";
import { useTranslations } from "next-intl";

import { NewIcon } from "components/NewIcon";
import { useUser } from "modules/user";
import Icon from "modules/DesignSystem/components/Icon";

import MenuMobile from "../../../MenuMobile";
import { userMenuItems } from "../consts";
import { Description, Title, Link, LogoutButtonWrapper, LogoutButton } from "./UserMenu.styled";

export const UserMenu = ({ onClose, open }) => {
  const t = useTranslations();
  const { email: userEmail, signOut } = useUser();

  const handleLogout = () => {
    signOut();
    onClose();
  };

  return (
    <MenuMobile open={open}>
      <MenuMobile.Header onClose={onClose}>
        <Title>
          <NewIcon icon="user" width="24" height="24" />
          <div>
            {t("navigation.my_account")}
            <Description>{userEmail}</Description>
          </div>
        </Title>
      </MenuMobile.Header>
      <MenuMobile.Content size={userMenuItems.length} open={open}>
        {userMenuItems.map((item) => (
          <Link key={item.name} route={item.route} title={t(item.name)} onClick={onClose}>
            {item.icon && <NewIcon icon={item.icon} width="32" height="32" />}
            {t(item.name)}
          </Link>
        ))}
        <LogoutButtonWrapper>
          <LogoutButton title={t("navigation.logout")} onClick={handleLogout}>
            <Icon name="logout" size={32} />
            {t("navigation.logout")}
          </LogoutButton>
        </LogoutButtonWrapper>
      </MenuMobile.Content>
    </MenuMobile>
  );
};

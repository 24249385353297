import { HalController } from "api-web-client";
import { getPrices } from "modules/Audiobook/utils";

export const DEFAULT_PRODUCTS_PER_PAGE = 30;

interface GTMProductInit {
  audiobook?: HALAudiobook | HALProduct;
  index?: number;
  isClubMember?: boolean;
  licenses?: HALLicenseChannels;
  listId?: string;
  listName?: string;
  product: HALProduct | HALAudiobook;
}

export const getProductType = (product: HALProduct | HALAudiobook) => {
  if ("app:cycle" in product.links || {}) {
    return "cycle";
  }

  return "one-off";
};

export const getGTMProduct = ({
  audiobook,
  index = 0,
  isClubMember,
  licenses,
  listId,
  listName,
  product,
}: GTMProductInit) => {
  const prices = getPrices(
    {
      price: product.data.price,
      price_for_subscribers: product.data.price_for_subscribers,
      discount_price: product.data.discount_price,
      lowest_price: product.data.lowest_price,
    },
    licenses,
    isClubMember
  );
  const discount = Number((prices.base - prices.final).toFixed(2));

  const result: { [key: string]: any } = {
    item_id: product.data.id,
    item_name: product.data.name,
    affiliation: "Audioteka",
    coupon: undefined,
    discount,
    index,
    item_brand: audiobook
      ? ((audiobook.embedded["app:publisher"] as HalController[]) || [])
          .map((publisher) => publisher.data.name)
          .join(", ")
      : product.data.description,
    item_category: audiobook ? audiobook.data.main_category_id : undefined,
    item_variant: getProductType(product),
    location_id: undefined,
    price: prices.final,
    quantity: 1,
  };

  if (listId !== undefined || listName !== undefined) {
    result.item_list_id = listId;
    result.item_list_name = listName;
  }

  return result;
};

const SELECTED_ITEM_WITH_LIST = "selectItemWithList";

export const setSelectedItemInLocalStorage = (productId: string, listData: { id: string; name: string }) => {
  localStorage.setItem(
    SELECTED_ITEM_WITH_LIST,
    JSON.stringify({
      productId,
      listId: listData.id,
      listName: listData.name,
    })
  );
};

type ListData = {
  listId?: string;
  listName?: string;
};

export const getListData = (audiobookId: string) => {
  const listData: ListData = {
    listId: undefined,
    listName: undefined,
  };

  const productList = localStorage.getItem(SELECTED_ITEM_WITH_LIST);

  if (productList) {
    const { productId, listId, listName }: Record<string, string> = JSON.parse(productList);

    if (productId === audiobookId) {
      listData.listId = listId;
      listData.listName = listName;
    }
  }

  return listData;
};

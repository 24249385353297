import React, { PureComponent } from "react";
import * as Sentry from "@sentry/nextjs";
import gtm from "react-gtm-module";
import sha1 from "crypto-js/sha1";
import sha256 from "crypto-js/sha256";
import api, { HalController } from "api-web-client";
import { useLocale, useTranslations } from "next-intl";

import { getDeviceID } from "modules/App";
import { isServer } from "utils/runtime";
import searchService from "modules/Search/SearchService";
import { getLocationInfo } from "utils/getLocationInfo";

import { useToaster } from "../Toaster";
import { userContext } from "./user.context";
import { userStore } from "./user.store";
import type { UserContext, UserErrors } from "./user.types";
import { setUser, subscribeUser } from "./user.utils";

type Props = {
  t: any;
  toaster: IToasterContext;
  locale: string;
  children: React.ReactNode;
};

interface State extends Omit<UserContext, "signIn" | "signOut" | "signUp"> {}

class UserProviderBase extends PureComponent<Props, State> {
  private _loadingId: NodeJS.Timeout | number;

  constructor(props) {
    super(props);

    this.state = {
      email: "",
      hal: null,
      hasMarketingConsent: false,
      id: "",
      isAlphaTester: isServer ? false : localStorage.getItem("alphaTest") === "true",
      isClubMember: false,
      isLoading: true,
      isLoggedIn: false,
    };

    if (!isServer) {
      const alphaTest = new URL(window.location.href).searchParams.get("alphaTest");

      if (alphaTest) {
        localStorage.setItem("alphaTest", alphaTest);
      }
    }
  }

  public componentDidMount() {
    this._fetchSearchIndexAndFilters();

    subscribeUser(this._userSubscriber);

    this._loadingId = setTimeout(() => {
      this.setState({ isLoading: false });
    }, 4000); // Prevent infinite loading. If something went wrong when unlock loading state after 4 seconds.

    this._checkAuthentication();

    userStore.subscribe(async (state) => {
      if (state === "signedIn") {
        await api.follow("app:me");
      } else {
        setUser(null);
      }
    });
  }

  private _userSubscriber = (user: HalController | null) => {
    clearTimeout(this._loadingId);

    if (user) {
      gtm.dataLayer({
        dataLayer: {
          user: {
            audioteka_club_member: user.data.has_club,
            em: sha256(user.data.email).toString(),
            iuid: user.data.id,
            optin: user.data.newsletter,
            registered: new Date(user.data.created_at).getTime(),
            tracking_id: user.data.tracking_id,
            uid: sha1(user.data.id).toString(),
            web_version: 1,
          },
        },
      });
    }

    this.setState(
      {
        email: user ? user.data.email : "",
        hal: user,
        hasMarketingConsent: user ? user.data.marketing_consent : false,
        id: user ? user.data.id : "",
        isAlphaTester: localStorage.getItem("alphaTest") === "true",
        isClubMember: user ? user.data.has_club : false,
        isLoading: false,
        isLoggedIn: Boolean(user),
      },
      () => this._fetchSearchIndexAndFilters()
    );
  };

  // eslint-disable-next-line class-methods-use-this
  private _checkAuthentication = async () => {
    const state = userStore.getState();

    try {
      await api.follow("app:me");

      if (state !== "signedIn") {
        userStore.setState("signedIn"); // Set state if it's different than stored
      }
    } catch {
      userStore.setState("signedOut"); // Failed to fetch app:me
    }
  };

  private _fetchSearchIndexAndFilters = async () => {
    let userOrVisitor = this.state.hal;

    if (!userOrVisitor) {
      userOrVisitor = await api.getVisitor(this.props.locale as string);
    }

    const algolia = await userOrVisitor.follow("app:algolia");

    searchService.setConfig(algolia.data);
  };

  public signIn: UserContext["signIn"] = async (email, password, reCaptcha) => {
    const { locale, t, toaster } = this.props;

    try {
      const { catalogId } = getLocationInfo(locale as string);

      const { expires_at } = await api.sendCommand("AuthenticateForWeb", {
        device_id: getDeviceID(),
        email,
        password,
        catalog_id: catalogId,
        recaptcha_token: reCaptcha,
      });

      userStore.setState("signedIn", new Date(expires_at));

      await api.follow("app:me"); // To update state

      gtm.dataLayer({
        dataLayer: {
          event: "login",
          login_method: "standard",
        },
      });

      return true;
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);

      toaster.showToast({
        autoClose: 10000,
        title: t("auth.sign_in.error_title"),
        message: t("auth.sign_in.error_message"),
        type: "error",
      });
    }

    return false;
  };

  public signOut: UserContext["signOut"] = async () => {
    try {
      const { locale } = this.props;
      const { catalogId } = getLocationInfo(locale as string);

      await api.sendCommand("LogoutForWeb", { catalog_id: catalogId });
    } finally {
      userStore.setState("signedOut");

      setUser(null);
    }
  };

  public signUp: UserContext["signUp"] = async (email, password, tos, marketing) => {
    this.setState({ isLoading: true });
    const errors: UserErrors = {};
    const { locale, t, toaster } = this.props;

    try {
      const { catalogId } = getLocationInfo(locale as string);

      await api.sendCommand("RegisterUser", {
        email,
        password,
        terms_of_service: tos,
        marketing_consent: marketing,
        catalog_id: catalogId,
      });

      gtm.dataLayer({
        dataLayer: {
          event: "sign_up_web",
        },
      });

      toaster.showToast({
        autoClose: 10000,
        title: t("auth.sign_up.success_title"),
        message: t("auth.sign_up.success_message"),
        type: "success",
      });
    } catch (error) {
      Sentry.captureException(error);

      if ("response" in error) {
        const response = await error.response.json();
        const errorList = response._embedded?.["app:error"] || [];

        errorList.forEach(({ property, code }) => {
          // eslint-disable-next-line default-case
          errors[property] = t(`auth.sign_up.error.${property}.${code.toLowerCase()}`);
        });
      }

      toaster.showToast({
        autoClose: 10000,
        title: t("auth.sign_up.error_title"),
        message: t("auth.sign_up.error_message"),
        type: "error",
      });

      this.setState({ isLoading: false });
    }

    return Object.keys(errors).length ? errors : null;
  };

  public render() {
    const { children } = this.props;

    return (
      <userContext.Provider
        value={{
          ...this.state,
          signIn: this.signIn,
          signOut: this.signOut,
          signUp: this.signUp,
        }}
      >
        {children}
      </userContext.Provider>
    );
  }
}

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const t = useTranslations();
  const locale = useLocale();
  const toaster = useToaster();

  return (
    <UserProviderBase t={t} locale={locale} toaster={toaster}>
      {children}
    </UserProviderBase>
  );
};

import styled, { css } from "styled-components";
import React, { MouseEventHandler } from "react";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  name: string;
  flipX?: boolean;
  flipY?: boolean;
  size?: number;
}

const svgList = [];

function importAll(r) {
  r.keys().forEach((key) => {
    svgList[key] = r(key);
  });
}

// @ts-ignore
importAll(require.context("!svg-react-loader!./icons/", true, /\.svg$/));

const IconStyled = styled.span<{ onClick?: MouseEventHandler }>`
  display: block;
  ${({ theme }) => {
    const flips: string[] = [];

    if (theme.flipX) {
      flips.push("scaleX(-1)");
    }

    if (theme.flipY) {
      flips.push("scaleY(-1)");
    }

    if (flips.length > 0) {
      return `transform: ${flips.join(" ")};`;
    }

    return null;
  }};
  height: ${({ theme }) => theme.size}px;
  width: ${({ theme }) => theme.size}px;

  ${({ onClick }) =>
    onClick
      ? css`
          cursor: pointer;
        `
      : ""}
  svg {
    display: block;
    margin: 0 auto;
    max-height: 100%;
    max-width: 100%;
    fill: currentColor;
  }
`;

const SvgIcon = React.forwardRef<HTMLDivElement, Props>(
  ({ name, flipX = false, flipY = false, size = 24, ...props }, ref) => {
    const iconPath = `./${name}.svg`;
    const Component = iconPath in svgList ? svgList[iconPath] : () => null;

    return (
      <IconStyled theme={{ flipX, flipY, size }} ref={ref} {...props}>
        <Component />
      </IconStyled>
    );
  }
);

export default styled(SvgIcon)``;

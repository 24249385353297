import { Catalog } from "resources/AudiotekaApi";
import { parsePrice } from "utils/string";

export type PricesWithCurrency = {
  base: string | null;
  club: string | null;
  discount: string | null;
  final: string | null;
  lowest: string | null;
};

type Prices = {
  base: number;
  final: number;
  withCurrency: PricesWithCurrency;
};

type ProductPrices = {
  price: string;
  price_for_subscribers: string;
  discount_price: string;
  lowest_price: string;
};

export function getPrices(productPrices: ProductPrices, licenses?: HALLicenseChannels, isClubMember = false): Prices {
  const { oneoff } = licenses?.data || {};

  let { price: base, price_for_subscribers: club, discount_price: discount, lowest_price: lowest } = productPrices;

  if (oneoff?.state === "CAN_BUY") {
    base = oneoff.price;
    lowest = oneoff.lowest_price;

    if (oneoff.available_discount === "SUBSCRIBER_DISCOUNT") {
      club = oneoff.discount_price;
    } else {
      discount = oneoff.discount_price;
    }
  }

  const allPrices: string[] = [base, discount as string, isClubMember && (club as string)]
    .filter(Boolean)
    .sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));
  const final = allPrices[0]; // lowest price available

  return {
    base: base ? parsePrice(base) : null,
    final: final ? parsePrice(final) : null,
    withCurrency: {
      base,
      club,
      discount,
      final,
      lowest,
    },
  };
}

export const getCategoryFromCatalog = (catalog: Catalog, categoryId: string | null) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const section of catalog._embedded["app:screen-section"]) {
    const category = section._embedded?.["app:category"].find((_category) => _category.id === categoryId);

    if (category) {
      return category;
    }
  }

  return null;
};

export function cutText(text: string, maxLength: number): string {
  if (text.length > maxLength) {
    return text.slice(0, maxLength - 3).replace(/ *$/, "...");
  }

  return text;
}

import React from "react";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";

import { NewIcon } from "components/NewIcon";
import { buildLink } from "utils/buildLink";

import { Form, Input, ButtonsWrapper, ClearButton, SearchButton } from "./SearchInput.styled";

type SearchInputProps = {
  className?: string;
  value: string;
  onChange: (value: string) => void;
  clearResults: () => void;
  onFocus: () => void;
};

export const SearchInput = ({ className, value, onChange, clearResults, onFocus }: SearchInputProps) => {
  const t = useTranslations();
  const { push, query } = useRouter();

  const ref = React.useRef<HTMLInputElement>(null);

  const onClear = () => {
    clearResults();
    ref.current?.focus();
  };

  const handleSearch = () => {
    push({
      pathname: buildLink("search", query.locale as string, { phrase: value }),
    });

    clearResults();
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (value.trim() === "") return;

    handleSearch();
    ref.current?.blur();
  };

  return (
    <Form className={className} onSubmit={onSubmit}>
      <Input
        ref={ref}
        type="text"
        placeholder={t("navigation.search")}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onFocus={onFocus}
      />
      <ButtonsWrapper>
        {value && (
          <ClearButton onClick={onClear}>
            <NewIcon icon="close" width="24" height="24" />
          </ClearButton>
        )}
        <SearchButton onClick={handleSearch} disabled={value.trim() === ""}>
          <NewIcon icon="search" width="24" height="24" />
        </SearchButton>
      </ButtonsWrapper>
    </Form>
  );
};

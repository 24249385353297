import { useRouter } from "next/router";
import { useLocale } from "next-intl";

import { getPathname } from "pathnames";

import { buildLink } from "./buildLink";

export const useRouterPush = () => {
  const { push } = useRouter();
  const locale = useLocale();

  const routerPush = (pathname: Parameters<typeof buildLink>[0], params?: Parameters<typeof buildLink>[2]) =>
    push(
      {
        pathname: getPathname(pathname),
        query: { ...params, locale },
      },
      buildLink(pathname, locale, params)
    );

  return routerPush;
};

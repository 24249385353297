import React from "react";
import { useTranslations } from "next-intl";
import styled from "styled-components";

const Root = styled.div`
  padding: 16px;
  text-align: center;
`;

export function Empty() {
  const t = useTranslations();

  return <Root>{t("cart.empty")}</Root>;
}

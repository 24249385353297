import React from "react";
import { useTranslations } from "next-intl";

import { NewIcon } from "components/NewIcon";

import { MenuMobileItem } from "../../MenuMobile";
import { Container, List, Item, Footer, CategoriesLink, CollapseButton } from "./CatalogDropdown.styled";

interface Props {
  isVisible: boolean;
  items: MenuMobileItem[];
  containerRef: React.RefObject<HTMLDivElement>;
  onToggle: () => void;
}

export const CatalogDropdown = ({ items, isVisible, containerRef, onToggle }: Props) => {
  const t = useTranslations();

  return (
    <Container open={isVisible} ref={containerRef}>
      <List itemsCount={items.length}>
        {items.map(({ accent, name, route, params, href }) => (
          <Item accent={accent} route={route} params={params} href={href} key={`catalog-${name}`}>
            {name}
          </Item>
        ))}
      </List>
      <Footer>
        <CategoriesLink route="catalog">
          <NewIcon icon="menu" width="20" height="20" />
          {t("navigation.all_categories")}
        </CategoriesLink>
        <CollapseButton onClick={onToggle}>
          {t("navigation.collapse")}
          <NewIcon icon="chevron-up" width="16" height="16" />
        </CollapseButton>
      </Footer>
    </Container>
  );
};

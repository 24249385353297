import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import styled, { css } from "styled-components";

import { isServer } from "utils/runtime";

import { NewIcon } from "components/NewIcon";
import { MEDIA_QUERY } from "modules/DesignSystem/breakpoint";

const Btn = styled.button<{ visible: boolean }>`
  align-items: center;
  background: #33adff;
  bottom: 34px;
  border: 0;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  padding: 0;
  position: fixed;
  transform: translateY(200%);
  transition: transform 0.25s ease-in-out;
  right: 34px;
  width: 48px;
  z-index: 10;

  @media screen and ${MEDIA_QUERY.xxl} {
    left: calc(50% + 688px);
    right: auto;
  }

  ${({ visible }) =>
    visible
      ? css`
          transform: translateY(0);
        `
      : ""}
`;

const ScrollTop: React.FC = () => {
  const root = useMemo(() => (isServer ? null : document.createElement("div")), []);

  const [isVisible, setVisible] = useState(false);
  const [isMounted, setMounted] = useState(false);

  const scrollTop = useCallback(() => {
    window.scrollTo({ behavior: "smooth", top: 0 });
  }, []);

  useEffect(() => {
    document.body.appendChild(root);
    setMounted(true);

    return () => {
      setMounted(false);
      document.body.removeChild(root);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (!isVisible && window.scrollY > 50) {
        setVisible(true);
      } else if (isVisible && window.scrollY < 50) {
        setVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isVisible]);

  return isMounted
    ? createPortal(
        <Btn type="button" onClick={scrollTop} visible={isVisible}>
          <NewIcon icon="chevron-up" width="28" height="28" />
        </Btn>,
        root
      )
    : null;
};

export default ScrollTop;

export const promoTypesConfig = {
  club: {
    cookie: {
      key: "w3club",
    },
    title: "top_promo_bar.club.title",
    cta: "top_promo_bar.club.cta",
    href: "top_promo_bar.club.url",
    style: {
      root: {
        background: "#d518ff",
        color: "#fff",
      },
      cta: {
        background: "#fff",
        borderRadius: "50vw",
        color: "#000",
      },
    },
  },
} as const;
